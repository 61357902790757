// hero themes
.hero {
    &.theme-pink {
        background-image: linear-gradient(138deg, #ffcdc8, #ecdeff);
        .title, .subtitle {
            color: #941b53;
        }
    }
    &.theme-blue {
        background-image: linear-gradient(138deg, #c8f5ff, #c5cfff);
        .title, .subtitle {
            color: #335189;
        }
    }
    &.theme-green {
        background-image: linear-gradient(138deg, #e9ffb7 0%, #96e6a1 100%);
        .title, .subtitle {
            color: #2e6e29;
        }
    }
    &.theme-purple {
        background-image: linear-gradient(138deg, #e2dcff 0%, #8eabfc 100%);
        .title, .subtitle {
            color: #393389;
        }
    }
    &.theme-orange {
        background-image: linear-gradient(138deg, #ffefc3, #ffe3ca);
        .title, .subtitle {
            color: #945507;
        }
    }
    &.taller {
        padding-top: 60px;
        @include until($tablet) {
            padding-top: 52px;
        }
    }
}

.hero-body {
    margin: 8rem;
    padding: 0;
    @include until($fullhd) {
        margin: 6rem;
    }
    @include until($widescreen) {
        margin: 4rem;
    }
    @include until($tablet) {
        margin: 2rem;
    }
}

.project-header {
    max-width: 1440px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5rem;
    @include until ($desktop) {
        display: block;
    }
}

.title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include until($desktop) {
        margin-top: 1.5rem;
        margin-bottom: 4.5rem;
    }
    @include until($tablet) {
        margin-top: 1.5rem;
        margin-bottom: 3.5rem;
    }
}

.title-container {
    .title {
        font-size: 3rem;
        @include until($fullhd) {
            font-size: 2.8rem;
        }
        @include until($widescreen) {
            font-size: 2.5rem;
        }
        @include until($tablet) {
            font-size: 1.8rem;
        }
    }
    .subtitle {
        font-size: 1.5rem;
        @include until($desktop) {
            font-size: 1.3rem;
        }
        @include until($tablet) {
            font-size: 1.1rem;
        }
    }
}

.feature-image {
    padding: 0;
    width: 100%;
    img {
        border-radius: 5px;
        display: block;
        max-height: 600px;
        margin: auto;
    }
}

.section {
    padding-top: 0;
    padding-bottom: 0;
}

.ss-gallery-item {
    img {
        display: block;
        max-height: 791px;
        margin: auto;
        @include until(2000px) {
            max-height: 605px;
        }
    }
}

.caption {
    color: #6f6f6f;
    font-size: 0.9em;
    text-align: center;
    max-width: 702px;
    margin: 3rem auto 10% auto;
    &:last-child {
        margin-bottom: 2%;
    }
    @include until($tablet) {
        text-align: left;
        margin-top: 2rem;
        margin-bottom: 4rem;
        &:last-child {
            margin-bottom: 0.5rem;
        }
    }
}

.text-left {
    text-align: left;
}

.text-section {
    padding: 0 1.5rem;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8rem;
    margin-bottom: 8rem;
    @include until($tablet) {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}

.content.content {
    margin: auto;
    font-size: 20px;
    @include until($tablet) {
        font-size: 17px;
    } 
    h2 {
        font-size: 2rem;
        margin-top: 8rem;
        margin-bottom: 2rem;
        @include until($tablet) {
            font-size: 1.4rem;
            margin-top: 3rem;
            margin-bottom: 1rem;
        }
    }
    h3 {
        font-size: 1.5rem;
        @include until($tablet) {
            font-size: 1.1rem;
        }
    }
    figure {
        margin-left: 0;
        margin-right: 0;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

.content {
    img {
        border-radius: 0.5rem;
        display: block;
    }
    .embed-viewer {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

.media-section {
    background: #f6f7f9;
    padding: 6rem;
    @include until($fullhd) {
        padding: 4rem;
    }
    @include until($widescreen) {
        padding: 2rem;
    }
    @include until($tablet) {
        padding: 1.5rem;
    }
    h3 {
        margin-bottom: 2rem;
        @include until($tablet) {
            margin-top: 0.5rem;
            margin-bottom: 1.5rem;
        }
    }
}

.media-container {
    max-width: 1440px;
    margin: auto;
    @include until(2000px) {
        max-width: 1100px;
    }
    &.smaller {
        max-width: 1000px;
    }
    .text-section {
        padding: 0;
        @include from($widescreen) {
            margin-bottom: 6rem;
        }
    }
}

.media-container.media-container-large {
    max-width: 1440px;
}

.grid-2col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    & + .grid-2col {
        margin-top: 1.5rem;
    }
    @include until($desktop) {
        gap: 1.5rem;
        & + .grid-2col {
            margin-top: 1.5rem;
        }
    }
    @include until($tablet) {
        display: block;
        .grid-item + .grid-item {
            margin-top: 1.5rem;
        }
    }
}

// TODO: refactor this
.grid-3col {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4rem;
    @media screen and (min-width: $tablet) and (max-width:$desktop) {
        display: block;
        .grid-item + .grid-item {
            margin-top: 3rem;
        }
        .grid-item {
            display: grid;
            grid-template-columns: 300px 1fr;
            gap: 2rem;
            .caption {
                margin: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
    @include until($tablet) {
        display: block;
        padding-top: 2rem;
        .grid-item + .grid-item {
            margin-top: 4rem;
        }
        img {
            max-height: 500px;
            margin: auto;
        }
    }
}

.scenario-grid {
    @include until($desktop) {
        display: block;
        .grid-item + .grid-item {
            margin-top: 1.5rem;
        }
    }
}

.scenario {
    padding: 2rem;
    font-size: 0.9em;
    border-left: 4px solid hsl(213deg, 70%, 70%);
    background-color: hsl(213deg, 70%, 95%);
    @include until($tablet) {
        padding: 1rem;
    }
}

.lightbox a, .lightbox img {
    display: block;
}
