/** 
    Overwrite simple-lightbox styles
**/

// Dark overlay instead of light
.sl-overlay {
    background: #000000;
    opacity: 0.85;
}

// White buttons and text
.sl-wrapper {
    .sl-close, .sl-navigation button, .sl-spinner, .sl-counter {
        color: #fff;
    }
}

// Stop background scrolling when you have an overlay open
html.has-lightbox {
    overflow: hidden;
}