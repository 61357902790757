// Load libraries
@import "bulma-custom.scss";
@import "bulma/bulma.sass";
@import "simplelightbox/src/simple-lightbox.scss";

// Load custom CSS
@import "lightbox.scss";
@import "components.scss";
@import "utilities.scss";
@import "home.scss";
@import "project-card.scss";
@import "project.scss";
@import "auth.scss";
