.password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.05);
    border-radius: 0.5rem;
    padding: 8rem;
    flex-direction: column;
    gap: 6rem;
    @include until($tablet) {
        padding: 8%;
        gap: 1rem;
    }
}

.password-card {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.password-field {
    flex-grow: 1;
}

.password-form {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
}