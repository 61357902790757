.container-md {
    max-width: 1200px;
    margin: auto;
}

.border {
    border: 1px solid rgb(231, 231, 231);
    border-radius: 0.5rem;
}

.border-mobile {
    border-radius: 0 0 1rem 1rem;
    @include until($tablet) {
        border: 1px solid rgb(231, 231, 231);
        border-radius: 0.5rem;
    }
}

.desktop-content {
    @include until($tablet) {
        display: none !important;
    }
}

.mobile-content {
    @include from($tablet) {
        display: none !important;
    }
}