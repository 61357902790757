.rainbow-line {
    height: 0.75rem;
    $hsl-light: 70%;
    background-image: linear-gradient(90deg, 
        hsl(5, 73%, $hsl-light) 0%, 
        hsl(39, 88%, $hsl-light) 25%,
        hsl(80, 60%, $hsl-light) 50%, 
        hsl(207, 60%, $hsl-light) 75%, 
        hsl(247, 60%, $hsl-light) 100%
    );
    background-size: 400%;
    animation: gradient 10s ease-in-out infinite;

    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.theme-rainbow {
    background-color: #f6f7f9;
    $hsl-light: 97%;
    background-image: linear-gradient(90deg, 
        hsl(5, 73%, $hsl-light) 0%, 
        hsl(39, 88%, $hsl-light) 25%,
        hsl(80, 60%, $hsl-light) 50%, 
        hsl(207, 60%, $hsl-light) 75%, 
        hsl(247, 60%, $hsl-light) 100%
    );
    background-size: 400%;
    animation: gradient 10s ease-in-out infinite;
    padding-top: 0.75rem;
    min-height: 100vh;
}

.homepage-section {
    padding: 10rem 2rem;
    @include until ($fullhd) {
        padding: 6rem 6rem;
    }
    @include until ($desktop) {
        padding: 5rem 2rem;
    }
    @include until($tablet) {
        padding: 3rem 1.5rem;
    }
    & + & {
        padding-top: 0;
    }
}

@keyframes gradient {
	0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}

.homepage-header .container-md {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    @include until($tablet) {
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
    }
}

.button-container {
    display: flex;
    gap: 1rem;
}

.button-container .button {
    padding: 1.5rem 1rem;
    background: rgba(0,0,0,0.04);
    border: none;
    transition: background-color .1s ease-in-out;
    &:hover {
        background: rgba(0,0,0,0.08);
    }
    img {
        margin-right: 0.5rem;
        opacity: 0.6;
    }
}

