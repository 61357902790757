.nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 1rem 8rem;
    @include until($fullhd) {
        padding: 1rem 6rem;
    }
    @include until($widescreen) {
        padding: 1rem 4rem;
    }
    @include until($tablet) {
        padding: 0.75rem 2rem;
    }
}

// only show this transparency if backdrop-filter is supported
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .nav-bar {
        background-color: rgba(255, 255, 255, .80);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }
}

.nav-bar-content {
    max-width: 1440px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-logo {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
}

.home-link, .sitename {
    color: #4a4a4a;
    font-weight: bold;
    font-size: 1.2rem;
}

.home-link {
    display: flex;
    align-items: center;
}

.progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    height: 0.25rem;
    background-color: grey;
    &.theme-pink {
        background-image: linear-gradient(90deg, hsl(5, 100%, 70%), hsl(265,100%, 70%));
    }
    &.theme-blue {
        background-image: linear-gradient(90deg, hsl(191, 100%, 70%), hsl(230, 100%, 70%));
    }
    &.theme-green {
        background-image: linear-gradient(90deg, hsl(78, 100%, 70%), hsl(128, 62%, 70%));
    }
    &.theme-purple {
        background-image: linear-gradient(90deg, hsl(250, 100%, 70%), hsl(224, 95%, 70%));
    }
    &.theme-orange {
        background-image: linear-gradient(90deg, hsl(44, 100%, 70%), hsl(28, 100%, 70%));
    }
}

.progress-bar-inner {
    height: 0.25rem;
    background-color: #f6f7f9;
    transform-origin: right;
}

.dark-button {
    padding: 1.5rem 1rem;
    background: rgba(0,0,0,0.04);
    border: none;
    transition: background-color .1s ease-in-out;
    &:hover {
        background: rgba(0,0,0,0.08);
    }
    img {
        margin-right: 0.5rem;
        opacity: 0.6;
    }
}

.list-about {
    background: #f6f7f9;
    padding: 0.75rem 0;
    border-radius: 1rem;
}

.list-about-item {
    padding: 0.75rem 1.5rem;
    display: flex;
    align-items: baseline;
    @include until($tablet) {
        flex-direction: column;
    }
    .list-about-label {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: bold;
        font-size: 1rem;
        color: #6e6e6e;
        min-width: 220px;
        @include until($tablet) {
            font-size: 0.9rem;
            margin-bottom: 0.5rem;
        }
    }
    .list-about-ul {
        margin-top: 0;
        margin-left: 1em;
    }
}

.video-player {
    border-radius: 0.5rem;
}

.prototype-iframe {
    display: block;
    width: 100%; 
    height: 75vh;
    border-radius: 0 0 1rem 1rem;
    @include until($tablet) {
        display: none;
    }
}

.mobile-prototype-iframe {
    display: block;
    width: 375px;
    height: 667px;
    margin: auto;
}

.mobile-prototype-container {
    border: 1rem solid rgba(0,0,0,0.05);
    border-top-width: 3rem;
    border-bottom-width: 3rem;
    border-radius: 20px;
    display: inline-block;
    @include until($tablet) {
        border-width: 2rem 0.5rem 2rem 0.5rem;
        img {
            max-height: 500px;
            width: auto;
        }
    }
}

.browser-frame {
    position: relative;
    background-color: hsl(230, 20%, 94%);
    border: 1rem solid hsl(230, 20%, 94%);
    border-top-width: 3rem;
    border-radius: 1.5rem;
    &::before {
        content: "•••";
        font-size: 2rem;
        color: rgba(0,0,0,0.2);
        position: absolute;
        top: -3rem;
        left: 0.1rem;
    }

    &.browser-frame-small {
        background-color: #edeff0;
        border-color: #edeff0;
        border-width: 0.5rem;
        border-top-width: 1.5rem;
        border-radius: 1rem;
        &::before {
            font-size: 1rem;
            top: -1.5rem;
            left: 0.1rem;
        }
    }
}

.phone-frame {
    background-color: #edeff0;
    border: 0.5rem solid #edeff0;
    border-top-width: 1.5rem;
    border-bottom-width: 1.5rem;
    border-radius: 1rem;
    img {
        max-height: 640px;
    }
}

@media screen and (max-width: $tablet) {
    .browser-frame {
        border-width: 0;
        &.browser-frame-small {
            border-width: 0.3rem;
        }
    }
    .browser-frame::before {
        display: none;
    }
}

blockquote {
    font-size: 0.9em;
}