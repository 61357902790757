.project-link {
    display: block;
}

.project-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 5rem;
    border-radius: 0.5rem;
    background: #e4e4e4;
    margin-bottom: 2rem;
    padding: 4rem;
    @include until($desktop) {
        padding: 3rem;  
        margin-bottom: 1.5rem;
    }
    @include until ($tablet) {
        display: block;
        padding: 2rem;
    }
    transition: transform .2s ease-in-out;
    &:hover {
        transform: scale(1.01);
        box-shadow: 0 1px 5px rgba(0,0,0, 0.10);
    }
}

.project-card.theme-pink {
    background: linear-gradient(138deg, #ffcdc8, #ecdeff);
    .project-title, .project-subtitle, .project-tag {
        color: #941b53;
    }
}

.project-card.theme-blue {
    background: linear-gradient(138deg, #c8f5ff, #c5cfff);
    .project-title, .project-subtitle, .project-tag {
        color: #335189;
    }
}

.project-card.theme-green {
    background: linear-gradient(138deg, #e9ffb7 0%, #96e6a1 100%);
    .project-title, .project-subtitle, .project-tag {
        color: #2e6e29;
    }
}

.project-card.theme-purple {
    background: linear-gradient(138deg, #e2dcff 0%, #8eabfc 100%);
    .project-title, .project-subtitle, .project-tag {
        color: #393389;
    }
}

.project-card.theme-orange {
    background: linear-gradient(138deg, #ffefc3, #ffe3ca);
    .project-title, .project-subtitle, .project-tag {
        color: #945507;
    }
}

.project-card {
    .project-title {
        font-weight: 600;
        line-height: 1.125;
        font-size: 2.5rem;
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        @include until($fullhd) {
            font-size: 1.7rem;
        }
        @include until($desktop) {
            font-size: 1.5rem;
        }
    }
    .project-subtitle {
        font-size: 1.5rem;
        @include until($desktop) {
            font-size: 1.1rem;
        }
    }
    .project-tags {
        display: flex;
        flex-flow: row wrap;
        gap: 0.5rem;
    }
    .project-tag {
        font-size: 0.9rem;
        padding: 0.5rem 0.75rem;
        border-radius: 0.5rem;
        background: rgba(255, 255, 255, 0.50);
        text-transform: uppercase;
        letter-spacing: 0.2px;
        font-weight: bold;
        @include until($tablet) {
            font-size: 0.9rem;
            padding: 0.5rem 1rem;
        }
    }
}

.project-card-left {
    @include until($tablet) {
        margin-bottom: 2rem;
    }
}

.project-card-right {
    text-align: center;
}

.project-card-image {
    display: block;
    max-height: 500px;
    margin: auto;
}
